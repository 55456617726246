export const AGREEMENT_TERMS = [
    "Aime Technology Co. maintains this application and various social media profiles to provide information regarding its products and services. The following Terms and Conditions (“Terms”) govern the use of the ProFile Aesthetic Management Application and/or software. Using the ProFile Aesthetic Management APP constitutes your agreement to the Terms, which Aime Technology Co. may update from time to time without prior notice. By using the ProFile Aesthetic Management App following such an update, you consent to be bound by the Terms as updated. We recommend that you review these Terms each time you visit the ProFile Aesthetic Management App or website. If you do not agree to these Terms at any time, you are not permitted to use the ProFile Aesthetic Management App. If you violate the Terms, Aime Technology Co. reserves the right to seek all available remedies at law and in equity.",
    "You agree to use the ProFile Aesthetic Management App for lawful purposes only and are prohibited from violating or attempting to violate the ProFile Aesthetic Management App security. All content you see on the ProFile Aesthetic Management App is intended only for personal, non-exclusive and non-commercial use, unless specified by the subscription method allowed under an approved product purchased through Aime Technology Co. Aime Technology Co. owns all of the right, title and interest to copyright in the pages, screens, text and images appearing on the ProFile Aesthetic Management App. The information and materials contained on the ProFile Aesthetic Management App cannot be downloaded, reproduced, published, modified, distributed, posted, or otherwise used except as provided herein without Aime Technology Co. express written permission or the patient to whom the information and materials apply.",
    "The information contained on the ProFile Aesthetic Management App is for general information purposes only. The Aime Technology Co. assumes no responsibility for errors or omissions by any party within the contents of the ProFile Aesthetic Management App.",
    "In no event shall Aime Technology Co. be held liable for any special, indirect, direct, consequential, or incidental damages or any damages whatsoever, whether in action of contract, negligence or other tort, arising out of or in connection with the use of the ProFile Aesthetic Management App or the contents of the ProFile Aesthetic Management App. Aime Technology Co. reserves the right to make additions, deletions, or modifications to the contents on the ProFile Aesthetic Management App at any time without prior notice. Aime Technology Co. does not warrant that the ProFile Aesthetic Management App is free of viruses or other harmful components.",
    "ProFile Aesthetic Management App may contain links to external websites that are not provided or maintained by or in any way affiliated with Aime Technology Co. Please note that the Aime Technology Co. does not guarantee the accuracy, relevance, timelines, or completeness of any information on these external websites. ProFile Aesthetic Management App offers health and fitness information and is designed for record keeping purposes online. You should not rely on this information as a substitute for, nor does it replace, professional medical advice, legal advice, medical legal advice, diagnosis, or treatment.",
    "ProFile Aesthetic Management App offers health and fitness information and is designed for record keeping purposes online. You should not rely on this information as a substitute for, nor does it replace, professional medical advice, legal advice, medical legal advice, diagnosis, or treatment.",
    "The medical information on the ProFile Aesthetic Management App is provided without any representations or warranties, express or implied.",
    "We do not warrant or represent that the medical information on the ProFile Aesthetic Management App will be constantly available, or available at all; or is true, accurate, complete, current or non-misleading.",
    "If you think you may be suffering from any medical condition, you, or your patient should seek immediate medical attention. You should never delay seeking medical advice, disregard medical advice or discontinue medical treatment because of information on the ProFile Aesthetic Management App.",
    "You acknowledge that, because of the limited nature of communication through the ProFile Aesthetic Management App interactive features, any assistance you may receive using any such features is likely to be incomplete and may even be misleading. Any assistance you may receive using any interactive of the ProFile Aesthetic Management App features does not constitute specific advice and accordingly should not be relied upon without further independent confirmation."
];
