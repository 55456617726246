import React, { Component } from "react";
import { FormInput, FormGroup, FormFeedback } from "shards-react";

interface CreatePasswordProps {
  onPasswordUpdate: Function;
  onPasswordValidityChange: Function;
}

interface CreatePasswordState {
  password: string;
  confirmedPassword: string;
}

export default class CreatePassword extends Component<
  CreatePasswordProps,
  CreatePasswordState
> {
  constructor(props: CreatePasswordProps) {
    super(props);
    this.state = {
      password: "",
      confirmedPassword: ""
    };
  }

  private passwordValidator(password: string): boolean {
    return (
      password.length >= 8 &&
      /[A-Z]/.test(password) && //Has uppercase
      /[a-z]/.test(password) && // Has lowercase
      /\d/.test(password)
    ); // Has number
  }

  handlePasswordChange = (event: React.ChangeEvent<any>) => {
    const password = event.target.value;
    this.setState({ password });
    this.props.onPasswordUpdate(
      password,
      this.state.confirmedPassword
    );
    const isTwoPasswordsTheSame: boolean = password === this.state.confirmedPassword;
    this.props.onPasswordValidityChange(!!(this.passwordValidator(this.state.password) && isTwoPasswordsTheSame));
  };

  handleConfirmPasswordChange = (event: React.ChangeEvent<any>) => {
    const { password } = this.state;
    const confirmedPassword = event.target.value;
    this.setState({ confirmedPassword });
    this.props.onPasswordUpdate(
      password,
      confirmedPassword
    );
    const isTwoPasswordsTheSame: boolean = password === confirmedPassword;
    this.props.onPasswordValidityChange(!!(this.passwordValidator(password) && isTwoPasswordsTheSame));
  };

  render() {
    return (
      <div>
        <FormGroup>
          <FormInput
            className="InputBox"
            type="password"
            id="#password"
            placeholder="New password"
            name="password"
            onChange={this.handlePasswordChange}
            style={
              !this.passwordValidator(this.state.password) &&
              this.state.password.length > 0
                ? {
                    borderColor: "#c4183c",
                    boxShadow: "0 5px 11.5px rgba(196,24,60,.1)",
                  }
                : {}
            }
            valid={this.passwordValidator(this.state.password)}
            invalid={
              !this.passwordValidator(this.state.password) &&
              this.state.password.length > 0
            }
          />
          <FormFeedback
            valid={this.state.password.length > 8}
            className="PasswordFeedback"
          >
            Minimum length of 8
          </FormFeedback>
          <FormFeedback
            valid={/[A-Z]/.test(this.state.password)}
            className="PasswordFeedback"
          >
            Uppercase letter
          </FormFeedback>
          <FormFeedback
            valid={/[a-z]/.test(this.state.password)}
            className="PasswordFeedback"
          >
            Lowercase letter
          </FormFeedback>
          <FormFeedback
            valid={/\d/.test(this.state.password)}
            className="PasswordFeedback"
          >
            Number
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <FormInput
            style={
              this.state.confirmedPassword !== this.state.password &&
              this.state.confirmedPassword.length > 0
                ? {
                    borderColor: "#c4183c",
                    boxShadow: "0 5px 11.5px rgba(196,24,60,.1)",
                  }
                : {}
            }
            className="InputBox"
            type="password"
            id="confirmed-password"
            placeholder="Confirm new password"
            name="confirmedPassword"
            onChange={this.handleConfirmPasswordChange}
            valid={
              this.state.confirmedPassword === this.state.password &&
              this.state.confirmedPassword.length > 0
            }
            invalid={
              this.state.confirmedPassword !== this.state.password &&
              this.state.confirmedPassword.length > 0
            }
          />
          <FormFeedback className="PasswordFeedback">
            <span role='img' aria-label='password feedback'>🚨 Passwords have to match! 🚨</span>
          </FormFeedback>
        </FormGroup>
      </div>
    );
  }
}
