import React from "react";
import Cards, { Focused } from "react-credit-cards";
import "react-credit-cards/lib/styles.scss";
import { Container, Row, Col, Form, Jumbotron, Button, Alert } from "react-bootstrap";
import "../styles/Payment.css";
import { AxiosError } from 'axios';
import momentTz from 'moment-timezone';
import { RegisterAdminPayload } from '../models/registration';
import { SubscriptionResponseMessage } from '../models/paymentResponse';
import { registerAdmin } from '../api/profile';
import { subscribeToApp, cancelSubscription } from '../api/authorizeNet';

interface PaymentFormState {
  cvc: string;
  expiry: string;
  focus: string;
  name: string;
  number: string;

  error?: string;

  registrationObject?: RegisterAdminPayload;
}
interface PaymentProps {
  className?: any;
  registrationObject?: RegisterAdminPayload;
  toNextState: Function;
  toPrevState: Function;
}

export default class PaymentForm extends React.Component<
  PaymentProps,
  PaymentFormState
> {
  constructor(props: PaymentProps) {
    super(props);

    this.state = {
      cvc: "",
      expiry: "",
      focus: "",
      name: "",
      number: "",

      registrationObject: props.registrationObject
    };
  }

  handleInputFocus = (event: React.ChangeEvent<any>) => {
    this.setState({ focus: event.target.name });
  };

  handleInputChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;

    this.setState({ [name]: value } as PaymentFormState);
  };

  handleSubmit = async () => {

    const { number, expiry, registrationObject } = this.state;

    try {
      const data = await subscribeToApp(number, expiry, registrationObject!.firstName, registrationObject!.lastName);

      const statusMessages: SubscriptionResponseMessage[] = data.messages.message.filter(m => m.code.startsWith('E'));
      if (statusMessages.length > 0) {
        for (const m of statusMessages) {
          this.updateErrorMessage(m.text);
        }
      } else {
        this.onPaymentResponse(momentTz().add(31, 'days').toISOString(), data.subscriptionId);
        this.register();
      }
    } catch (err) {
      this.updateErrorMessage('Failed to process your payment information at this time, please try again later');
    }
  };

  onPaymentResponse = (subscriptionExpiry: string, subscriptionId: string) => {
    this.setState({ registrationObject: {
      ...this.state.registrationObject!,
      subscriptionExpiry,
      subscriptionId
    }})
  }

  updateErrorMessage = (message: string, toDelete?: string) => {
    if (toDelete) {
      this.setState({ error: undefined });
    }
    this.setState({ error: message });
  }

  register = async () => {
    try {
      await registerAdmin(this.state.registrationObject!);

      this.props.toNextState();
    } catch (error) {
      if (error && error.response) {
        const axiosError = error as AxiosError;
        switch (axiosError.response?.status) {
          case 422:
            this.updateErrorMessage('Invalid sign up occurred, please fix your registration form information');
            break;
          default:
            this.updateErrorMessage(axiosError.response?.data.message);
            break;
        }
      }

      await cancelSubscription(this.state.registrationObject!.subscriptionId!);
    }
  };

  render() {
    const { registrationObject } = this.props;
    const { error } = this.state;
    if (!registrationObject) {
      return <div>
        <Alert variant='danger' dismissible size={10}>
          <Alert.Heading>Please go back to the previous screen</Alert.Heading>
          <p>Please fill out the form in the previous screen in order to proceed</p>
        </Alert>

        <Button onClick={this.props.toPrevState.bind(this)} >Previous page</Button>
      </div>;
    }

    return (
      <div id="PaymentForm" className={this.props.className}>
        <Container fluid>
          {error && <Alert variant='warning' dismissible size={10}>
            <Alert.Heading>Something has failed</Alert.Heading>
            <p>{error}</p>
          </Alert>}
          <Row>
            <Jumbotron className="PaymentDescription">
              <h1>Almost Done!</h1>
              <p>
                ProFile is currently free! After a 60 day free trail, you'll be
                charged $120/month.
              </p>
              <p>
                <Button variant="primary">Learn more</Button>
              </p>
            </Jumbotron>
          </Row>

          <Row>
            <Col>
              {" "}
              <Cards
                cvc={this.state.cvc}
                expiry={this.state.expiry}
                focused={this.state.focus as Focused}
                name={this.state.name}
                number={this.state.number}
              />
            </Col>
            <Col>
              <Form className="PaymentForm">
                <Form.Group>
                  <Form.Control
                    name="name"
                    placeholder="Name"
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="tel"
                    name="number"
                    placeholder="Card Number"
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="month"
                    name="expiry"
                    placeholder="Valid Thru"
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    name="cvc"
                    placeholder="CVC"
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className="SubmitButtonDiv">
            <Button className="SubmitButton" type="submit" size="lg" onClick={this.handleSubmit}>
              Register Company
            </Button>
          </Row>
        </Container>
      </div>
    );
  }
}
