import momentTz from 'moment-timezone';
import { SubscriptionResponse } from '../models/paymentResponse';
import axios from 'axios';
export const subscribeToApp = async (cardNumber: string, expirationDate: string, firstName: string, lastName: string) => {
  const time = momentTz();

  const today = time.tz("America/Denver").format("yyyy-MM-DD");

  const paymentData = {
    ARBCreateSubscriptionRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_ANET_LOGIN_ID,
        transactionKey: process.env.REACT_APP_ANET_TRANSACTION_KEY,
      },
      subscription: {
        name: "ProFile application subscription",
        paymentSchedule: {
          interval: {
            length: "30",
            unit: "days",
          },
          startDate: today,
          totalOccurrences: "9999", // ongoing occurrence
          trialOccurrences: "2",
        },
        amount: "12.00",
        trialAmount: "0.00",
        payment: {
          creditCard: {
            cardNumber: cardNumber,
            expirationDate: expirationDate,
          },
        },
        billTo: {
          firstName: firstName,
          lastName: lastName,
        },
      },
    },
  };

  const response = await axios.post(
    process.env.REACT_APP_ANET_ENDPOINT as string,
    paymentData
  );

  return response.data as SubscriptionResponse;
};

export const cancelSubscription = async (subscriptionId: string) => {
  const cancelSubscriptionPayload = {
    ARBCancelSubscriptionRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_ANET_LOGIN_ID,
        transactionKey: process.env.REACT_APP_ANET_TRANSACTION_KEY
      },
      subscriptionId
    }
  }

  await axios.post(process.env.REACT_APP_ANET_ENDPOINT as string, cancelSubscriptionPayload);
}
