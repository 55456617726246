import React, { Component } from "react";

import "pure-react-carousel/dist/react-carousel.es.css";
import AutoSlider from "./AppCarousel";
import { Container, Row, Col } from "react-bootstrap";
import profileLogo from "../assets/profile_logo.png";
import { ReactComponent as AppStoreIcon } from "../assets/appStoreWhite.svg";

export class RegistrationPromo extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col className="RegistrationPromoContainer">
            <img className="ImageSize" src={profileLogo} alt="logo" />
            <h4 style={{ display: "none" }}>
              Book & Record Appointments. Manage clients. Take payments. Stay
              organized while offering a more personalized touch to your
              business & clientele.
            </h4>
            <h5>
              Spend less time shuffling papers and more time with your patients.
            </h5>
            <h6>Features:</h6>
            <ul>
              <li>HIPAA compliant</li>
              <li>Book and manage appointments</li>
              <li>Take payments</li>
              <li>Add custom procedures</li>
              <li>Add patients and providers</li>
              <li>View patient history and previous appointments</li>
              <li>Take photos and use them to record your appointment</li>
              <li>
                Create or upload forms and have your patient sign off on them
              </li>
              <li>Costs of procedures automatically totaled for you </li>
              <li>Store information securely on encrypted servers</li>
            </ul>
            <div style={{ textAlign: "center", paddingTop: "25px" }}>
              <a
                href="https://www.apple.com/ios/app-store/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppStoreIcon />
              </a>
            </div>
          </Col>
          <Col>
            <div className="Slider">
              <AutoSlider />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
