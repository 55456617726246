import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import "../styles/AdminRegistration.css";
import { AdminRegistrationForm } from "./AdminRegistrationForm";
import { RegistrationPromo } from "./RegistrationPromo";
import { Container, Col, Row, Card, Alert } from 'react-bootstrap';
import PaymentForm from "./PaymentForm";
import { RegisterAdminPayload } from '../models/registration';

interface AdminProps {
  className: string | object;
}

interface AdminState {
  registrationState: RegistrationState;
  registrationPayload?: RegisterAdminPayload;
}

enum RegistrationState {
  FILLING_FORM = 'FILLING_FORM',
  PAYMENT = 'PAYMENT',
  SUCCESS = 'SUCCESS'
}

export class RegistrationStateManagement extends Component<AdminProps, AdminState> {
  constructor(props: AdminProps) {
    super(props);
    this.state = {
      registrationState: RegistrationState.FILLING_FORM,
      registrationPayload: undefined
    };
  }

  // When the form is submitted we want to stop displaying it and display the payment (TODO: animate transition)
  onFormSubmit = (registrationPayload: RegisterAdminPayload) => {
    this.setState({ registrationState: RegistrationState.PAYMENT, registrationPayload });
  };

  toPrevStateFromPayment = () => {
    this.setState({ registrationState: RegistrationState.FILLING_FORM });
  }

  toSuccessPage = () => {
    this.setState({ registrationState: RegistrationState.SUCCESS });
  }

  conditionalRendering() {
    switch (this.state.registrationState) {
      case RegistrationState.FILLING_FORM:
        return <AdminRegistrationForm submitForm={this.onFormSubmit} />
      case RegistrationState.PAYMENT:
        return <PaymentForm
          registrationObject={this.state.registrationPayload}
          toPrevState={this.toPrevStateFromPayment}
          toNextState={this.toSuccessPage} />
      case RegistrationState.SUCCESS:
        return <div><Alert variant='success' dismissible size={10}>
          <Alert.Heading>Success</Alert.Heading>
            <p>You have been registered successfully. Please download the app from iOS app store.</p>
          </Alert>
        </div>;
    }
  }

  render() {
    return (
      <Container className="FormContainer" fluid>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                {this.conditionalRendering()}
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <RegistrationPromo />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
