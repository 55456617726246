import React from "react";
import { CarouselProvider, Slider, Slide, Image } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "../styles/AdminRegistration.css";

import screen1 from "../assets/screen-1.jpg";
import screen2 from "../assets/screen-2.jpg";
import screen3 from "../assets/screen-3.jpg";
import screen4 from "../assets/screen-4.jpg";
import screen5 from "../assets/screen-5.jpg";
import screen6 from "../assets/screen-6.jpg";

export default class extends React.Component {
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={1242}
        naturalSlideHeight={2688}
        totalSlides={6}
        hasMasterSpinner
        isPlaying
      >
        <Slider>
          <Slide index={0}>
            <Image className="ImageSize" src={screen1} hasMasterSpinner={true} />
          </Slide>
          <Slide index={1}>
            <Image className="ImageSize" src={screen2} hasMasterSpinner={true} />
          </Slide>
          <Slide index={2}>
            <Image className="ImageSize" src={screen3} hasMasterSpinner={true} />
          </Slide>
          <Slide index={3}>
            <Image className="ImageSize" src={screen4} hasMasterSpinner={true} />
          </Slide>
          <Slide index={4}>
            <Image className="ImageSize" src={screen5} hasMasterSpinner={true} />
          </Slide>
          <Slide index={5}>
            <Image className="ImageSize" src={screen6} hasMasterSpinner={true} />
          </Slide>
        </Slider>
      </CarouselProvider>
    );
  }
}
