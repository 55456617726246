import React, { Component } from "react";
import { Form, Select, Button, InputOnChangeData, Header, Modal, Icon, Comment, Divider } from "semantic-ui-react";
import states from "../assets/us_states.json";
import CreatePassword from "./CreatePassword";
import { AGREEMENT_TERMS } from '../assets/agreement';
import { RegisterAdminPayload } from '../models/registration';

interface AdminRegistrationFormState {
  firstName: string;
  lastName: string;
  companyName: string;
  companyEmail: string;
  companyPhone: string;
  companyStreetAddress: string;
  companyCity: string;
  companyState: string;
  companyZip: string;
  fedTaxId: string;
  password: string;
  confirmedPassword: string;
  isPasswordValid: boolean;
  isModalOpen: boolean;
}

interface AdminRegistrationFormProps {
  submitForm: Function;
}

export class AdminRegistrationForm extends Component<
  AdminRegistrationFormProps,
  AdminRegistrationFormState
> {
  constructor(props: AdminRegistrationFormProps) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      companyName: "",
      companyEmail: "",
      companyPhone: "",
      companyStreetAddress: "",
      companyCity: "",
      companyState: "",
      companyZip: "",
      fedTaxId: "",
      password: "",
      confirmedPassword: "",
      isPasswordValid: false,
      isModalOpen: false
    };
  }

  private passwordValidator(password: string): boolean {
    return (
      password.length >= 8 &&
      /[A-Z]/.test(password) && //Has uppercase
      /[a-z]/.test(password) && // Has lowercase
      /\d/.test(password)
    ); // Has number
  }

  updatePasswordValidity = (isValid: boolean) => {
    this.setState({ isPasswordValid: isValid });
  }

  isFormValid = () => {
    const { firstName, lastName, companyName, companyEmail,
      companyStreetAddress, companyCity, companyState, companyZip,
      companyPhone, fedTaxId, isPasswordValid } = this.state;
    const isValid = !!(firstName && lastName && companyName && companyEmail
      && companyStreetAddress && companyCity && companyState
      && companyZip && companyPhone && fedTaxId && isPasswordValid);
    return isValid;
  }

  handleModalOpen = () => this.setState({ isModalOpen: true });

	handleModalClosed = () => this.setState({ isModalOpen: false });

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    this.setState({
      ...this.state,
      [event.target.name]: data.value,
    });
  };

  handleCompanyStateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    this.setState({
      ...this.state,
      companyState: data.value,
    });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    // Convert state to the expected shape of API
    const payload: RegisterAdminPayload = {
      username: this.state.companyEmail,
      newPassword: this.state.password,
      phoneNumber: this.state.companyPhone.startsWith('+1') ? this.state.companyPhone : `+1${this.state.companyPhone}`,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      address: `${this.state.companyStreetAddress}, ${this.state.companyCity}, ${this.state.companyState} ${this.state.companyZip}`,
      companyEmail: this.state.companyEmail,
      federalTaxId: this.state.fedTaxId,
      companyName: this.state.companyName,
    };

    this.props.submitForm(payload);
  };

  updatePasswordFields = (password: string, confirmedPassword: string) => {
    this.setState({ password, confirmedPassword });
  };

  render() {
    const formValid = this.isFormValid();
    const { isModalOpen } = this.state;
    return (
      <div>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="firstName"
              placeholder="First Name"
              width={8}
              onChange={this.handleChange}
            />
            <Form.Input
              name="lastName"
              placeholder="Last Name"
              width={8}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Field>
            <Form.Input
              name="companyName"
              placeholder="Company Name"
              onChange={this.handleChange}
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Input
              name="companyEmail"
              placeholder="Company e-mail address"
              onChange={this.handleChange}
            />
            <Form.Input
              name="companyPhone"
              placeholder="Company Phone Number"
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Field>
            <Form.Input
              name="companyStreetAddress"
              placeholder="Company Street Address"
              onChange={this.handleChange}
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Input
              name="companyCity"
              placeholder="Company City"
              onChange={this.handleChange}
            />
            {/* TODO: Not working */}
            <Form.Input
              name="companyState"
              control={Select}
              options={states.usStates.map((state) => ({
                key: state,
                text: state,
                value: state,
              }))}
              placeholder="State"
              search
              selection
              fluid
              onChange={this.handleCompanyStateChange}
            />
            <Form.Input
              name="companyZip"
              placeholder="Company Zip/Postal"
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Field>
            <Form.Input
              placeholder="Federal tax ID number"
              name="fedTaxId"
              onChange={this.handleChange}
            />
          </Form.Field>

          <hr
            style={{
              color: "rgba(30, 71, 94, 1)",
              backgroundColor: "gray",
              height: ".5px",
              borderColor: "#000000",
              borderWidth: 0,
            }}
          />
          <CreatePassword onPasswordUpdate={this.updatePasswordFields} onPasswordValidityChange={this.updatePasswordValidity} />
          <Modal
            trigger={
              <Button size="big" color="teal" disabled={!formValid} onClick={this.handleModalOpen}>
                Next
              </Button>}
            open={isModalOpen}
            onClose={this.handleModalClosed}
            size="large"
            style={{ position: 'relative', height: 'auto' }}
          >
            <Header icon="browser" content="Terms & Conditions" />
            <Modal.Content scrolling>
              {AGREEMENT_TERMS.map((term) => (
                <Comment size="large">
                  {term}
                  <Divider />
                </Comment>
              ))}
            </Modal.Content>
            <Modal.Actions>
              <div className="SubmitButtonDiv">
                <Button primary color="teal" type="submit" inverted onClick={this.handleSubmit}>
                  Proceed <Icon name='chevron right' />
                </Button>
              </div>
            </Modal.Actions>
          </Modal>
        </Form>
      </div>
    );
  }
}
