import React from "react";
import "./styles/App.css";
import "semantic-ui-css/semantic.min.css";
import "shards-ui/dist/css/shards.min.css";
import { RegistrationStateManagement } from './components/RegistrationManagement';

export default function App() {
  return (
    <div className="App ">
      <RegistrationStateManagement className="RegistrationStateManagement" />
    </div>
  );
}
